import { useState, useEffect } from 'react';
import { ApiPath } from '../assets/common/base-url';

export const useFetchPatient = (idValue) => {

    const [patient, setPatient] = useState({});

    let error = "";
    const params = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        'method': 'GET',
    }

    useEffect(() => {
        //Fetch the patients data...
        fetch(ApiPath + "query/search/" + idValue, params)
            ?.then(response => (response.json()))
            .then(res => {

                if (res.statusCode === 200) {
                    //Save this for future use...
                    setPatient(res.data);

                } else {
                    error = res.message;
                }

            })
            .catch(err => {
                error = err.message;
            })

    }, [])

    return ({ error, patient });

}
