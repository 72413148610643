import { useState } from "react";
import { CryptoApiPath } from "../../assets/common/base-url";
import { constants } from "../../assets/common/constants";

export const CancelAppointmentDialog = props => {

    const [txtCancelReason, setTxtCancelReason] = useState('');

    const cancelAppointment = () => {
        //Run the subroutine to cancel the appointment by#
        // making an API call to the backend...
        if (txtCancelReason === "") {
            //Enter reason for cancelling appointment..
            props.showToast("Enter reason for appointment cancellation", "exclamation");
            return;
        }

        props.setIsLoaderVisible(true);

        const options = constants.postOptions;
        const params = "?reason=" + txtCancelReason + "&meeting_id=" + props.meeting_id;

        //Else, proceed to update...
        fetch(CryptoApiPath + "cancelAppointment" + params, options)
            .then(response => (response.json()))
            .then(response => {
                props.setIsLoaderVisible(false);

                if (response) {
                    if (response.status === 200) {
                        //Go ahead and show message box...
                        props.showToast("Appointment cancelled!", "success");
                        props.setCancelSuccessful(true);

                        props.hideDialog();
                        
                    } else {
                        props.showToast(response.message, "exclamation");
                    }
                }

            })
            .catch(error => {
                props.setIsLoaderVisible(false);
                props.showToast(error.message, "exclamation");

            })
    }

    return (
        <div className="modal fade show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="nully">
                            <i className='icofont-close-squared'></i> Cancel Meeting / Appointment
                        </h2>
                    </div>
                    <div className="modal-body">
                        <div className="form-row">
                            <div className="input-group">
                                <label>Enter reason for appointment cancellation</label>
                                <textarea
                                    className="form-control"
                                    rows={4}
                                    value={txtCancelReason}
                                    onChange={e => setTxtCancelReason(e.target.value)}

                                />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            id="btnUpdate"
                            onClick={cancelAppointment}
                            className="btn-main mr-1"
                        ><i className="icofont-verification-check"></i> Proceed
                        </button>
                        <button
                            className="btn-main-outline"
                            onClick={() => props.hideDialog()}
                        ><i className="icofont-logout" /> Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}