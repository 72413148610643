import { useEffect, useState, useRef } from "react";
import { ScheduledAppointmentDialogTabHeaders } from "../components/scheduled-appointment-dialog-tab-headers";
import { Medications } from "../tab-pages/medications";
import { Notes } from "../tab-pages/notes";
import { ApiPath } from "../../assets/common/base-url";
import { useFetchPatient } from "../../hooks/useFetchPatient";
import { getAgeInYears, timeToHoursAndMinutesString, toMySqlDateFormat } from "../../assets/common/operations";
import { Link } from "react-router-dom";

const ScheduledAppointmentDialog = (props) => {

    const [selectedTab, setSelectedTab] = useState(1);
    const [txtNotes, setTxtNotes] = useState('');
    const [appointments, setAppointments] = useState([])
    const [selectedPatient, setSelectedPatient] = useState({});
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [selectedAppointmentIndex, setSelectedAppointmentIndex] = useState(0);
    const docData = useRef(JSON.parse(sessionStorage.getItem("doctor")));

    useEffect(() => {
        //Fetch on form load...
        const properSelectedDate = toMySqlDateFormat(props.selectedEventDate);
        const timeValueOfToday = new Date(properSelectedDate).getTime();

        const daysEnd = timeValueOfToday + (60 * 60 * 24 * 1000) + 2000;    //Remove date in parenthesis later....

        const options = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            },
            'method': 'GET',
        }

        props.setIsLoaderVisible(true);

        fetch(`${ApiPath}query/getAppointments/${timeValueOfToday}/${daysEnd}`, options)
            .then(response => (response.json()))
            .then(response => {
                props.setIsLoaderVisible(false);

                if (response.status === "success" && response.statusCode === 200) {
                    //Set the result as the output...
                    const filteredData = response.data.filter(item => item.startTime >= timeValueOfToday && item.startTime <= daysEnd)
                    setAppointments(filteredData);

                } else {
                    props.showToast('Failed to fetch data. Please try again after some time.', 'exclamation');
                }
            })

            .catch(err => {
                props.setIsLoaderVisible(false);
                props.showToast(err.message, "exclamation");
            })

    }, [])

    const appointmentsItems = appointments.length > 0 && appointments?.map((item, index) => {
        return (
            <PatientAppointments
                key={index}
                index={index}
                data={item}
                setSelectedPatient={setSelectedPatient}
                setSelectedAppointment={setSelectedAppointment}
                setSelectedAppointmentIndex={setSelectedAppointmentIndex}
                active={selectedAppointmentIndex === index ? 'selected' : ''}
            />
        )
    })

    return (
        <div className="modal show fade">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="nully">
                            <i className="icofont-calendar" /> Scheduled Appointments</h2>
                    </div>
                    <div className="modal-body">
                        <div className="date-caption">
                            <i className="icofont-clock-time" />
                            <h4>{props.selectedEventDate}</h4>
                        </div>
                        <div className="display-group">
                            <div className="display-group-lhs">
                                <div className="display-title">
                                    <h4>Appointments</h4>
                                </div>
                                <div className="appt-group">
                                    {appointmentsItems}
                                </div>
                            </div>
                            <div className="display-group-rhs">
                                <div className="patient-data">
                                    {
                                        Object.keys(selectedAppointment).length === 0
                                            ? <div className="select-item">
                                                <img src="/img/svg/select-item.svg" alt="" />
                                                <h4>Select Patient</h4>
                                            </div>
                                            : <div className="patient-header">
                                                <img src={selectedPatient?.photo} alt="" />
                                                <h3>{selectedPatient?.name}</h3>
                                                <span>{selectedPatient?.gender} • {getAgeInYears(selectedPatient?.birthDate)}yrs</span>
                                                <Link
                                                    className='btn-main mt-2 mb-1'
                                                    to={selectedAppointment && selectedPatient && '/meeting/meeting-page?meeting_id=' +
                                                        selectedAppointment?.meetingId + '&doctor_id=' + selectedAppointment?.doctorId +
                                                        '&gravatar=' + docData.photo + '&p_dlg_vis=true' +
                                                        '&patient_id=' + selectedPatient?.uidNo + '&tk=' + sessionStorage.getItem('token')}
                                                    target='_blank'>
                                                    <i className='icofont-video-cam' /> Join Video Consultation
                                                </Link>
                                            </div>
                                    }

                                    {
                                        Object.keys(selectedAppointment).length > 0 &&
                                        <div className="patient-details">
                                            {/* We should have some medical history data here... */}
                                            {/* MEDICAL HISTORY GROUP / TABS */}
                                            <ScheduledAppointmentDialogTabHeaders
                                                selectedTab={selectedTab}
                                                setSelectedTab={setSelectedTab}
                                            />
                                            {
                                                selectedTab === 1 && Object.keys(selectedPatient).length > 0 && <Medications
                                                    data={selectedPatient}
                                                    showToast={props.showToast}
                                                    setIsLoaderVisible={props.setIsLoaderVisible}

                                                />
                                            }
                                            {selectedTab === 2 && <Notes
                                                setInput={setTxtNotes}
                                                inputValue={txtNotes}
                                            />}
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn-main mr-1 d-none">
                            <i className="icofont-retweet" /> Reschedule</button>
                        <button
                            className="btn-main-outline"
                            onClick={() => props.hideDialog()}
                        ><i className="icofont-logout" /> Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScheduledAppointmentDialog;

const PatientAppointments = props => {

    //Always get each patients data...
    const { patient } = useFetchPatient(props.data.patientId);
    const setData = () => {
        props.setSelectedPatient(patient);
        props.setSelectedAppointment(props.data);
        props.setSelectedAppointmentIndex(props.index);

        //Set the patient to the localStorage...
        localStorage.setItem('patient', JSON.stringify(patient));

    }

    // useEffect(() => {
    //     if (props.index === 0) {
    //         // When the index is zero, ensure to make the
    //         // selectedAppointment selected as this..
    //         props.setSelectedPatient(patient);
    //         props.setSelectedAppointment(props.data);
    //         props.setSelectedAppointmentIndex(props.index);
    //     }

    // }, [])

    return (
        <div className={"appt " + props.active} onClick={setData}>
            <div className="appt-main">
                <img src={patient.photo} alt="" />
                <div className="name-and-define">
                    <h5>{patient?.name}</h5>
                    <span>{patient?.gender} • {getAgeInYears(patient.birthDate)}yrs</span>
                </div>
            </div>
            <div className="others">
                <i className="icofont-ui-delete" />
                <span className="period">
                    <i className="icofont-stopwatch" />
                    {`${timeToHoursAndMinutesString(parseInt(props.data?.startTime))} - ${timeToHoursAndMinutesString(parseInt(props.data?.startTime) + props.data?.duration)}`}
                </span>
            </div>
            <i className="icofont-rounded-right" />
        </div>
    )
}
