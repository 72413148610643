import { Link, useHistory } from "react-router-dom"
import { API_ACCESS_KEY } from "../assets/common/base-url";
import { useEffect, useState } from "react";

export const NavBar = props => {

    const history = useHistory();
    const [myLocation, setMyLocation] = useState("Lagos, Nigeria");
   /// const [geocoder] = useState(() => new google.maps.Geocoder())

    const signOut = () => {
        //Sign out
        localStorage.setItem("token", null);
        sessionStorage.setItem("token", null);
        sessionStorage.setItem("id_val", null);
        sessionStorage.setItem('patient', null);

        history.push("/")
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            props.showToast("Geolocation is not supported by this browser.", "exclamation");
        }
    }

    const showPosition = (position) => {

        //First, get the lattitude and longitude...
        const locLat = position.coords.latitude;
        const locLong = position.coords.longitude;

        //codeLatLng(locLat, locLong)

        //Fire the API for reverse geocoding...
        fetch(`http://api.positionstack.com/v1/reverse?access_key=${API_ACCESS_KEY}&query=${locLat},${locLong}&output=json`)
            .then(response => (response.json()))
            .then(response => {
                //Display results...
                if (response) {
                    setMyLocation(response.data[0].region + ", " + response.data[0].country)
                }
            })

    }

    // // const codeLatLng = (lat, lng) => {
    // //     var latlng = new google.maps.LatLng(lat, lng);

    // //     geocoder.geocode({ 'latLng': latlng }, (results, status) => {
    // //         if (status == google.maps.GeocoderStatus.OK) {
    // //             console.log(results)

    // //             if (results[1]) {
    // //                 //formatted address
    // //                 console.log(results[0].formatted_address)
    // //                 //find country name
    // //                 for (var i = 0; i < results[0].address_components.length; i++) {
    // //                     for (var b = 0; b < results[0].address_components[i].types.length; b++) {

    // //                         //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
    // //                         if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
    // //                             //this is the object you are looking for
    // //                             city = results[0].address_components[i];
    // //                             break;
    // //                         }
    // //                     }
    // //                 }
    // //                 //city data
    // //                 setMyLocation(city.short_name + " " + city.long_name)


    // //             } else {
    // //                 console.log("No results found");
    // //             }
    // //         } else {
    // //             console.log("Geocoder failed due to: " + status);
    // //         }
    // //     });
    // // }

    useEffect(() => getLocation(), [])

    return (
        <div className='nav-bar'>
            <Link to='/patients/digital-health-passport' className='logo'>
                <img src='/mah-logo.png' alt='' />
            </Link>
            <div className='links'>
                <Link className={props.activeLink === 1 ? 'active' : ''} to='/patients/digital-health-passport'>Home</Link>
                <Link className={props.activeLink === 2 ? 'active' : ''} to='/patients/profile'>Profile</Link>
                <Link className={props.activeLink === 3 ? 'active' : ''} to='#'>Appointments</Link>

            </div>
            <div className='image'>
                {/* <h5><i className='icofont-location-pin'></i> {props.data.address[0]?.city}{props.data.address[0].country && `, ${props.data.address[0].country}`}</h5> */}
                <h5><i className='icofont-location-pin'></i>{myLocation}</h5>
                <div className='img-container'>
                    <img
                        className='profile-img'
                        src={props.data.photo || '/portfolio/avatar.png'}
                        alt=''
                        onClick={signOut}
                    />
                </div>

            </div>

            <div className="img-flyout d-none">
                <Link to="/patients/profile"><i className='icofont-ui-user' />My Profile</Link>
                <Link to="/" onClick={signOut}><i className='icofont-sign-out' />Sign out</Link>
            </div>
        </div>
    )
}
