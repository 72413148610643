import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DoctorsDialog } from './doctors-dialog';
import { ApiPath } from '../assets/common/base-url';
import { SplitDateFromTimestamp } from '../assets/common/split-date';

const ScheduleAppointment = props => {

    const [doctorSelected, setDoctorSelected] = useState('')
    const [showDoctorDialog, setShowDoctorDialog] = useState(false);
    const [doctorsData, setDoctorsData] = useState({})

    const [txtPatientsInstruction, setTxtPatientsInstruction] = useState('')
    const [txtSupportingInfo, setTxtSupportingInfo] = useState('')
    const [fileConsent, setFileConsent] = useState(false)
    const [specialObject, setSpecialObject] = useState('')

    const history = useHistory();

    const scheduleAppointment = () => {
        //Use the endpoint for the appointment scheduling
        // On the Guarav part of the project...
        const payLoad = {
            "meetingId": specialObject.meetingID,
            "speciality": specialObject.speciality,
            "startTIme": specialObject.startTime,
            "duration": specialObject.duration,
            "patientInstruction": txtPatientsInstruction,
            "patientId": props.data.uidNo,
            "doctorId": specialObject.doctor_id,
            "supportingInfo": {
                "filesConsent": fileConsent ? "Yes" : "No"
            }
        }

        const options = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            },
            'method': 'POST',
            'body': JSON.stringify(payLoad)
        }

        props.setIsLoaderVisible(true);

        fetch(ApiPath + "appointment/bookAppointment", options)
            .then(response => (response.json()))
            .then(response => {
                props.setIsLoaderVisible(false);
                if (response.status === "success") {
                    //Success message
                    props.showToast("Appointment with doctor scheduled successfully!", "success");

                    props.setAppointmentBooked()
                    props.hideAppointmentDialog();

                    //Then, refresh the page...
                    history.push("/patients/digital-health-passport");

                }
            })
            .catch(err => {
                props.setIsLoaderVisible(false);
                props.showToast(err, 'exclamation')
            })

    }

    //Fetch the data on form load...
    const params = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
        },
        'method': 'GET'
    };

    useEffect(() => {
        //Use the selected doctor to reload the doctor's profile and show data here...

        if (doctorSelected) {
            props.setIsLoaderVisible(true);

            fetch(ApiPath + "query/search/" + doctorSelected, params)
                ?.then(response => (response.json()))
                .then(res => {
                    props.setIsLoaderVisible(false)

                    if (res.statusCode === 200) {
                        setDoctorsData(res.data);

                    } else {
                        props.showToast(res.message, 'exclamation');
                    }

                })
                .catch(err => {
                    props.setIsLoaderVisible(false);
                    props.showToast(err.message, 'exclamation');
                })


            //Alwasy remember to reset this so that we dont have an infinite loop
            setDoctorSelected(false);
        }

    }, [doctorSelected])

    return (
        <>
            <div className="dialog-background fade">
                <div className="dialog-container" style={{ width: '800px' }}>
                    <div className="dialog-header">
                        <h2 className="nully" style={{ color: 'var(--bluish)', fontSize: '20px', fontWeight: '600' }}><i className="icofont-stopwatch"></i> Schedule Appointment</h2>
                    </div>
                    <div className="dialog-body">

                        <div className='form-row-1-2'>
                            <div className='input-group'>
                                <label>Choose Doctor</label>
                                <button
                                    className="btn-main-outline"
                                    onClick={() => setShowDoctorDialog(true)}
                                >Browse for Doctor...
                                </button>
                            </div>
                            <div className='doctor-area'>
                                <div className={doctorSelected !== '' ? 'chosen-doctor' : 'd-none'}>
                                    <img src='doctorsData.photo' alt='' />
                                    <div className="details">
                                        <h3>{doctorsData && "Dr. " + doctorsData.name}</h3>
                                        <p>{doctorsData?.specialization?.type}</p>
                                        <div>
                                            <b style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }}><i className='icofont-clock-time' /></b>
                                            <span>{specialObject !== '' && SplitDateFromTimestamp(specialObject.startTime).dateTime}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={doctorSelected === '' ? 'no-doctor-yet' : 'd-none'}>
                                    <img src='/res/doctor.png' alt='' width={50} />
                                    <h2>No Doctor Chosen yet...</h2>
                                </div>
                            </div>
                        </div>
                        <div className="form-row mb-2 mt-2" style={{ borderBottom: '2px solid var(--light-golden-rod)' }}></div>
                        <div className="form-row-2">
                            <div className='appt-settings'>
                                <h3>Appointment Settings</h3>
                                <div className='form-row'>
                                    <div className='input-group'>
                                        <label>Patients Instructions</label>
                                        <textarea
                                            id='txtPatientsInstruction'
                                            value={txtPatientsInstruction}
                                            onChange={e => setTxtPatientsInstruction(e.target.value)}
                                            rows={3}
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className='input-group'>
                                        <label>Supporting Info</label>
                                        <textarea
                                            id='txtSupportingInfo'
                                            value={txtSupportingInfo}
                                            onChange={e => setTxtSupportingInfo(e.target.value)}
                                            rows={3}
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className='form-row'>
                                    <div className='check-box'>
                                        <label>
                                            <input
                                                className='custom-checkbox'
                                                id='fileConsent'
                                                value={fileConsent}
                                                onChange={() => setFileConsent(!fileConsent)}
                                                type='checkbox'
                                            /> Allow Doctor to view my medical history
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className={doctorSelected !=='' ? 'payment-summary' : 'd-none'}>
                                <h3>Payment Summary</h3>
                                <table className='payment-summary-table'>
                                    <tbody>
                                        <tr>
                                            <td>Consultation Fee</td>
                                            <td>$650</td>
                                        </tr>
                                        <tr>
                                            <td>Wallet Balance</td>
                                            <td>$650</td>
                                        </tr>
                                        <tr>
                                            <td>BTC Equivalent</td>
                                            <td>0.002965</td>
                                        </tr>
                                        <tr>
                                            <td>Total Payable</td>
                                            <td>$650</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>By continuing, you agree to the <a href='#'>Terms of Use, Virtual Care Service Terms,</a> and
                                    <a href='#'> the Premium Services Subscription Agreement.</a></p>
                            </div>
                        </div>

                    </div>
                    <div className="dialog-footer main">
                        <button
                            id="btnUpdate"
                            onClick={scheduleAppointment}
                            className="btn-main mr-1"
                        ><i className="icofont-checked"></i> Pay and Schedule Appointment
                        </button>
                        <button
                            className="btn-main-outline"
                            onClick={() => props.hideAppointmentDialog()}
                        >Cancel</button>
                    </div>
                </div>
            </div>

            {
                showDoctorDialog && <DoctorsDialog
                    showToast={props.showToast}
                    setIsLoaderVisible={props.setIsLoaderVisible}
                    hideDoctorsDialog={() => setShowDoctorDialog(false)}
                    setDoctorSelected={setDoctorSelected}
                    data={props.data}
                    setSpecialObject={setSpecialObject}

                />
            }

        </>
    );
}

export default ScheduleAppointment;
