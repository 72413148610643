import { useState, useEffect } from "react";
import { ApiPath } from "../assets/common/base-url";

import { DHPHeader } from "../components/dhp-header";
import { NavBar } from "../components/nav-bar";
import { Appointments } from "./side-tab-pages/appointments";
import { MedicalRecords } from "./side-tab-pages/medical-records";
import { SideBarItem } from "./others/side-bar-item";
import { sideBarList } from "./others/side-bar-list";
import { MedicalInsurance } from "./side-tab-pages/medical-insurance";
import { GeneticInformation } from "./side-tab-pages/genetic-information";
import { VaccinationReports } from "./side-tab-pages/vaccination-reports";
import { HealthProfileBar } from "./others/health-profile-bar";


const DigitalHealthPassport = props => {

    const [selectedIndex, setSelectedIndex] = useState(1);
    const [appointmentBooked, setAppointmentBooked] = useState(false);
    const [patientsData] = useState(JSON.parse(sessionStorage.getItem('patient')))
    const [cancelState, setCancelState] = useState(false)
    const [doctorsData, setDoctorsData] = useState()
    const [pastAppointments, setPastAppointments] = useState([])


    const sideBarItems = sideBarList.map(item => {
        return <SideBarItem
            key={item.index}
            headerText={item.headerText}
            contentText={item.contentText}
            index={item.index}
            selectedIndex={selectedIndex}
            setSelectedIndex={value => setSelectedIndex(value)}
        />
    })

    useEffect(() => {

        //Dates to use...
        const threeMonthsAgo = new Date().getTime() - (60 * 60 * 24 * 90 * 1000);
        const threeMonthsTime = new Date().getTime() + (60 * 60 * 24 * 90 * 1000);

        const options = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            },
            'method': 'GET',
        }

        props.setIsLoaderVisible(true);

        fetch(`${ApiPath}query/getAppointments/${threeMonthsAgo}/${threeMonthsTime}`, options)
            .then(response => (response.json()))
            .then(response => {
                props.setIsLoaderVisible(false);
                if (response.statusCode === 200 && response.status === "success") {
                    //Retrieve the result...
                    const result = response.data?.filter(item => item.patientId === patientsData.uidNo)

                    //Set other parameters...
                    setDoctorsData(result[0]);
                    // setPastAppointments(result.filter((elt, index) => index !== 0))
                    setPastAppointments(result)

                    if (result.length > 0) {
                        //Fiter generated result thus...
                        setAppointmentBooked(true);
                    } else {
                        setAppointmentBooked(false);
                    }

                } else {
                    props.showToast('Failed to fetch data. Refresh page to retry.', 'exclamation');
                }
            })

            .catch(err => {
                props.setIsLoaderVisible(false);
                props.showToast(err.message, "exclamation");
            })

    }, [cancelState, appointmentBooked])

    useEffect(() => {
        //Set item on form load...
        localStorage.setItem('patient', JSON.stringify(patientsData));
    }, [])

    return (
        <div className='dhp-container'>
            <NavBar
                activeLink={1}
                picture={patientsData.photo}
                data={patientsData}
            />
            <DHPHeader barcodeLink={patientsData.qrCode} />
            <HealthProfileBar />
            <div className='dhp-body'>
                <div className="left-side-bar">
                    {sideBarItems}
                </div>
                <div className='main-content'>
                    {selectedIndex === 1 && <Appointments
                        showToast={props.showToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                        appointmentBooked={appointmentBooked}
                        setAppointmentBooked={setAppointmentBooked}
                        doctorsData={doctorsData}
                        pastAppointments={pastAppointments}
                        setCancelState={setCancelState}
                    />

                    }
                    {selectedIndex === 2 && <MedicalRecords
                        showToast={props.showToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                        doctorsData={doctorsData}
                        data={patientsData}
                    />}

                    {selectedIndex === 3 && <MedicalInsurance />}
                    {selectedIndex === 4 && <GeneticInformation />}
                    {selectedIndex === 5 && <VaccinationReports />}

                </div>
            </div>
        </div>
    );
}

export default DigitalHealthPassport;
