import { useEffect, useState } from 'react';
import { ProfilePage } from "./profile-pages/profile-page"
import { DoctorsProfileHeader } from './components/profile-header';
import { DoctorsProfileRightSidebar } from './components/profile-right-sidebar';
import { DoctorsProfileSideBar } from './components/profile-sidebar';
import { DoctorsProfileSubHeader } from './components/profile-sub-header';
import AppointmentSetupPage from './profile-pages/appointment-setup';
import AvailabilitySetup from './profile-pages/availability-setup';
import ChargeSheet from './profile-pages/charge-sheet';
import { ApiPath } from '../assets/common/base-url';

const DoctorsProfile = props => {

    const [doctorsData, setDoctorsData] = useState(JSON.parse(sessionStorage.getItem("doctor")));

    const [appointments, setAppointments] = useState([]); //Sample data for now...
    const [pastAppointments, setPastAppointments] = useState([]);

    const [menuActive, setMenuActive] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(1);

    useEffect(() => {
        //Import the data for now...
        setDoctorsData(JSON.parse(sessionStorage.getItem("doctor")));

        //Also, get the data for appointments, based on the current date...
        //Dates to use...
        const currentTimeStamp = new Date().getTime();
        const oneMonthsTime = new Date().getTime() + (60 * 60 * 24 * 30 * 1000);

        const options = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            },
            'method': 'GET',
        }

        props.setIsLoaderVisible(true);

        fetch(`${ApiPath}query/getAppointments/${currentTimeStamp}/${oneMonthsTime}`, options)
            .then(response => (response.json()))
            .then(response => {
                props.setIsLoaderVisible(false);
                if (response.statusCode === 200 && response.status === "success") {
                    //Retrieve the result...
                    // and filter to a date not less than today...
                    const result = response.data.filter(item => item.startTime > currentTimeStamp);
                    setAppointments(result)

                    // Past appointments...
                    const otherResult = response.data.filter(item => item.startTime < currentTimeStamp);
                    setPastAppointments(otherResult);

                } else {
                    props.showToast('Failed to fetch data. Refresh page to retry.', 'exclamation');
                }
            })

            .catch(err => {
                props.setIsLoaderVisible(false);
                props.showToast(err.message, "exclamation");
            })


        //eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="background">
                <DoctorsProfileHeader
                    doctorsData={doctorsData}
                />
                <div className='body-container'>
                    <DoctorsProfileSideBar
                        selectedMenu={selectedMenu}
                        setSelectedMenu={setSelectedMenu}
                        appointments={appointments}
                        pastAppointments={pastAppointments}
                        menuActive={menuActive}
                        showToast={props.showToast}
                        setIsLoaderVisible={props.setIsLoaderVisible}
                    />
                    <div className='main'>
                        <DoctorsProfileSubHeader
                            menuActive={menuActive}
                            setMenuActive={setMenuActive}
                        />
                        <div className='sub-main'>
                            <div className='central-panel'>
                                {selectedMenu === 1 && <AvailabilitySetup
                                    showToast={props.showToast}
                                    setIsLoaderVisible={props.setIsLoaderVisible}
                                    data={doctorsData}
                                />}
                                {selectedMenu === 2 && <ProfilePage
                                    showToast={props.showToast}
                                    setIsLoaderVisible={props.setIsLoaderVisible}
                                    data={doctorsData}
                                />}
                                {selectedMenu === 3 && <AppointmentSetupPage />}
                                {selectedMenu === 4 && <ChargeSheet />}

                            </div>
                            <DoctorsProfileRightSidebar

                            />
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default DoctorsProfile;
