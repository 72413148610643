import { shortDateString } from '../../assets/common/operations';
import { useFetchPatient } from '../../hooks/useFetchPatient';

export const AppointmentBox = props => {

    const { patient } = useFetchPatient(props.patient);

    return (
        // onClick={() => props.showAppointmentDialog()}
        <div className='appt-box' >
            <i className='icofont-calendar' />
            <div className='details'>
                <h5>{patient.name}</h5>
                <span>
                    <i className='icofont-stopwatch' />
                    <p>{shortDateString(props.date)} • {props.time}</p>
                </span>
            </div>
        </div>
    )
}
