import { Prescriptions } from "../../dhp/medical-records/prescriptions"

export const Medications = props => {

    return (
        <div className="appointment">
            <Prescriptions
                data={props.data}
                showToast={props.showToast}
                setIsLoaderVisible={props.setIsLoaderVisible}
            />
        </div>
    )
}
